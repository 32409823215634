import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonCard, IonList, IonItem, IonLabel, useIonViewDidEnter, IonButton, IonToast, IonInput } from '@ionic/react'
import React, { useState } from 'react'
import { LayanV2 } from '../service/LayanV2'

const EditProfile: React.FC = () => {
  const [name, setName] = useState<string>("")
  const [currentPassword, setCurrentPassword] = useState<string>("")
  const [passwordNew, setPasswordNew] = useState<string>("")
  const [passwordConfirm, setPasswordConfirm] = useState<string>("")
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<string>()
  
  useIonViewDidEnter(async () => {
    const user = await new LayanV2().getUser()
    setName(user?.data.user.name)
  })

  const Submit = async () => {
    try {
      const toSubmit = { name }
      if (passwordNew !== "") {
        if (passwordNew !== passwordConfirm) {
          setError("New passwords didn't match!")
          return
        }
        const passChangeRes = await new LayanV2().changePassword(currentPassword, passwordNew)
        
        console.log(passChangeRes)
      }
      const res = await new LayanV2().updateProfile(toSubmit)
      console.log(res)
      setSuccess("Values successfully saved.")

    } catch(e) {
      setError("Old password is wrong!")
      console.error(e)
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main" />
          </IonButtons>
          <IonTitle>Edit Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonList>
            <IonItem>
              <IonLabel color="medium" position="floating">New Name</IonLabel>
              <IonInput value={name} onIonChange={e => setName(e.detail.value!)}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel color="medium" position="floating">Current Password</IonLabel>
              <IonInput value={currentPassword} type="password" onIonChange={e => setCurrentPassword(e.detail.value!)}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel color="medium" position="floating">New Password</IonLabel>
              <IonInput value={passwordNew} type="password" onIonChange={e => setPasswordNew(e.detail.value!)}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel color="medium" position="floating">Confirm Password</IonLabel>
              <IonInput value={passwordConfirm} type="password" onIonChange={e => setPasswordConfirm(e.detail.value!)}></IonInput>
            </IonItem>
          </IonList>
        </IonCard>
      </IonContent>
      <IonToast position="bottom" color="danger" isOpen={!!error} message={error} duration={3000} onDidDismiss={() => setError('')} />
      <IonToast position="bottom" color="success" isOpen={!!success} message={success} duration={3000} onDidDismiss={() => setError('')} />
      <IonButton expand="block" slot="bottom" onClick={Submit}>Submit</IonButton>
    </IonPage>
  )
}

export default EditProfile
