import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonSlide,
  IonSlides,
  IonToast,
  useIonViewDidEnter
} from '@ionic/react'
import { arrowForwardOutline, moonSharp, sunnyOutline } from 'ionicons/icons'
import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import config from '../config/config.json'
import { Firebase } from '../service/Firebase'
import { Socket } from '../service/Socket'
import { LayanV2 } from '../service/LayanV2'

const Login: React.FC = () => {
  const [application, setApplication] = useState<string>()
  const [applicationName, setApplicationName] = useState<string>()
  const [username, setUsername] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [isDarkMode, setIsDarkMode] = useState<boolean>(localStorage.getItem('theme') === 'dark')

  const history = useHistory()

  useIonViewDidEnter(() => {
    Cookies.remove('Authorization')
    Cookies.remove('RefreshToken')
    localStorage.removeItem('RefreshToken')
    localStorage.removeItem('url')
    localStorage.removeItem('user')
    localStorage.removeItem('firebaseToken')
  })

  const login = async () => {
    if (await document.querySelector('ion-slides')?.getActiveIndex() === 0) {
      if (!application) {
        return setError('Application name can\'t be blank')
      }
      try {
        const app = await new LayanV2(`${config.url}/api/v2`).getApplication(application!)
        setApplicationName(app.data.application.title)
        localStorage.setItem('url', `${config.url}/api/v1/${application}`)
        localStorage.setItem('application', application)
        return document.querySelector('ion-slides')?.slideNext()
      } catch (error) {
        return setError(error.response?.status === 404 ? 'Application not found' : error.response?.data?.error?.message || error.message)
      }
    }

    if (await document.querySelector('ion-slides')?.getActiveIndex() === 1) {
      if (!application) {
        document.querySelector('ion-slides')?.slidePrev()
        return setError('Application name can\'t be blank')
      }
      if (!username) {
        return setError('Username can\'t be blank')
      }
      if (!password) {
        return setError('Password can\'t be blank')
      }

      setLoading(true)
      try {
        const respLogin = await new LayanV2().login(username, password)
        localStorage.setItem('user', JSON.stringify({
          _id: respLogin.data.user.mongoId || respLogin.data.user.id,
          role: respLogin.data.user.role,
          name: respLogin.data.user.name,
          isAvailable: respLogin.data.user.isAvailable,
          status: respLogin.data.user.status || { name: 'Online' },
          phone: respLogin.data.user.phone
        }))

        Cookies.set('Authorization', respLogin.data.jwt.token)
        Cookies.set('RefreshToken', respLogin.data.jwt.refreshToken)
        localStorage.setItem('Authorization', respLogin.data.jwt.token)
        localStorage.setItem('RefreshToken', respLogin.data.jwt.refreshToken)

        const getApp = await new LayanV2().getApplicationDetail()
        localStorage.setItem('AccessToken', getApp.data.application.accessToken)

        await new LayanV2().updateProfile({ isAvailable: true, status: 'Online' })

        Firebase.run()
        Socket.build(config.url, respLogin.data.jwt.token)
        return history.replace('/main')
      } catch (error) {
        console.log(error)
        setError('Username/password are wrong')
      }
      setLoading(false)
    }
  }

  const changeTheme = (checked: boolean) => {
    setIsDarkMode(checked)
    checked ? localStorage.setItem('theme', 'dark') : localStorage.removeItem('theme')
    checked ? document.body.classList.add('dark') : document.body.classList.remove('dark')
  }

  return (
    <IonPage>
      <IonContent>
        <IonButton style={{ position: 'absolute', right: 0, zIndex: 999999 }} fill="clear" onClick={() => changeTheme(!isDarkMode)}>
          <IonIcon slot="icon-only" icon={isDarkMode ? moonSharp : sunnyOutline} />
        </IonButton>
        <IonSlides style={{ height: '100%' }} pager={false} options={{ speed: 500, allowTouchMove: false }}>
          <IonSlide>
            <div className="container">
              <img style={{ width: '50%', maxWidth: '340px', marginBottom: '20px' }} src={localStorage.getItem('theme') === 'dark' ? './assets/layan-logo-inverse.svg' : './assets/layan-logo.svg'} alt="logo" />
              <IonCard>
                <IonCardContent>
                  <IonItem>
                    <IonLabel position="floating">Application Name</IonLabel>
                    <IonInput value={application} onIonChange={e => setApplication(e.detail.value!)} />
                  </IonItem>
                </IonCardContent>
              </IonCard>
            </div>
          </IonSlide>
          <IonSlide>
            <div className="container">
              { applicationName ? (
                <>
                  <h2>Hello, {applicationName} 👋</h2>
                  <p>Let's login and start helping customer</p>
                </>
              ) : '' }
              <br />
              <IonCard>
                <IonCardContent>
                  <IonItem>
                    <IonLabel position="floating">Username</IonLabel>
                    <IonInput value={username} onIonChange={e => setUsername(e.detail.value!)} />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput value={password} type="password" onIonChange={e => setPassword(e.detail.value!)} />
                  </IonItem>
                </IonCardContent>
              </IonCard>
            </div>
            <IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message='Logging you in...' />
          </IonSlide>
        </IonSlides>
        <IonFab vertical="bottom" horizontal="end">
          <IonFabButton onClick={() => login()}>
            <IonIcon icon={arrowForwardOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
      <IonToast position="bottom" color="danger" isOpen={!!error} message={error} duration={3000} onDidDismiss={() => setError('')} />
    </IonPage>
  )
}

export default Login
