import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonCardHeader,
  IonCardTitle,
  IonPage,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar,
  IonList,
  IonCard,
  useIonViewDidEnter,
  IonButton,
  IonTextarea,
  IonToast,
  IonCardContent,
  IonText,
  IonIcon
} from '@ionic/react'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import {DateParser} from'../utils/DateParser'
import { Layan } from '../service/Layan'
import CustomFieldRenderer from '../components/CustomFieldRenderer'
import { arrowRedoSharp } from 'ionicons/icons'
import { LayanV2 } from '../service/LayanV2'
import { Field } from '../types'

interface PageProps extends RouteComponentProps<{
  id: string
}> {}


const TicketDetail: React.FC<PageProps> = ({ match }) => {
  const [createdAt, setCreatedAt] = useState<string>()
  const [solvedBy, setSolvedBy] = useState<string>()
  const [solvedAt, setSolvedAt] = useState<string>()
  const [number, setNumber] = useState<number>()
  const [assignedTo, setAssignedTo] = useState<string>()
  const [assignedAt, setAssignedAt] = useState<string>()
  const [fields, setFields] = useState<any>()
  const [isSolved, setIsSolved] = useState<boolean>(false)
  const [values, setValues] = useState<any>()
  const [assignedTos, setAssignedTos] = useState<any>()
  const [assignmentNote, setAssignmentNote] = useState<any>()
  const [ticketNoteValue, setTicketNoteValue] = useState<string>()
  const [tags, setTags] = useState<any>(null)
  const [error, setError] = useState<string>()

  const LoadFields = async (): Promise<any[]> => {
    const fieldsLoaded = await new LayanV2().getFields()
    const { data } = fieldsLoaded
    const { fields } = data

    return fields
  }

  const FillKeyWithValues = (i: number, value: string, name: string | null) => {
    values[i]["value"] = value
    if (name !== null) {
      values[i]["name"] = name
    }
  }

  const Submit = async () => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].isRequired && values[i].value === "") {
        setError('Required values must not be empty!')
        return
      }
    }
    try {
      const res = await new Layan().updateTicket(match.params.id, { fields: values })
      console.log(res)
    } catch(e) {
      console.error(e)
    }
  }

  const SolvedByComponent = (props: any) => {
    return props.solvedBy === "" ? null :
    (
      <>
        <IonItem>
          <IonLabel color="medium">Solved By</IonLabel>
          <IonLabel>{props.solvedBy}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel color="medium">Solved At</IonLabel>
          <IonLabel>{props.solvedAt}</IonLabel>
        </IonItem>
      </>
    )
  }

  const NoteField = (props: any) => {
    if (props.solvedBy === null) {
      return (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Ticket Note</IonCardTitle>
          </IonCardHeader>
          <IonItem>
            <IonTextarea disabled={props.isSolved} value={props.ticketNoteValue}></IonTextarea>
          </IonItem>
        </IonCard>
      )
    }

    if (props.solvedBy === null && props.ticketNoteValue === null) {
      return null
    }

    if (props.solvedAt && props.ticketNoteValue) {
      return (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Ticket Note</IonCardTitle>
          </IonCardHeader>
          <IonItem>
            <IonTextarea disabled={props.isSolved} value={props.ticketNoteValue}></IonTextarea>
          </IonItem>
        </IonCard>
      )
    }

    return null
  }

  const Tags = (props: any) => {
    return props.tags === "" ? null :
    (
      <IonItem>
        <IonLabel color="medium">Tags</IonLabel>
        <IonLabel>{props.tags}</IonLabel>
      </IonItem>
    )
  }

  const CustomFields = (props: any) => {
    if (fields !== undefined) {
      return props.fields.length === 0 ? null :
      (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Custom Fields</IonCardTitle>
          </IonCardHeader>
          <CustomFieldRenderer
            data={props.fields}
            isEditable={props.isSolved}
            values={props.values}
            FillKeyWithValues={FillKeyWithValues}
          />
        </IonCard>
      )
    }
    return null
  }

  useIonViewDidEnter(async () => {
    const ticketDetailsLoaded = await new Layan().getTicketDetails(match.params.id)
    const fields = await LoadFields()
    const { data } = ticketDetailsLoaded
    const { ticket } = data

    const dat = fields.sort((a, b) => a.order - b.order).map((field: Field) => ({ ...field, _id: String(field.mongoId || field.id) }))
    const vals = fields.map((field: Field) => {
      return {
        fieldId: field.mongoId || field.id,
        value: ticket.fields.find((val: any) => val.fieldId === field.id || field.mongoId === val.fieldId)?.value || ''
      }
    })

    setNumber(ticket.number)
    setCreatedAt(DateParser(ticket.createdAt))
    setSolvedAt(ticket.solvedAt === null ? "" : DateParser(ticket.solvedAt))
    setSolvedBy(ticket.solvedBy === undefined ? "" : ticket.solvedBy.name)
    setAssignedAt(DateParser(ticket.assignedAt))
    setAssignedTo(ticket.assignedTo.name)
    setAssignedTos(ticket.assignedTos)
    setAssignmentNote(ticket.assignmentNote)
    setIsSolved(ticket.solvedBy === undefined ? false : true)
    setValues(vals)
    setTags(ticket.tags.filter((e: any) => e !== null ).join(', '))
    setFields(dat)
    setTicketNoteValue(ticket.note === null ? null : ticket.note)
  })

  const button = isSolved || !fields?.length ? null : <IonButton expand="block" slot="bottom" onClick={Submit}>Submit</IonButton>

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main" />
          </IonButtons>
          <IonTitle>Ticket #{number}</IonTitle>
          { !solvedAt ? (
            <IonButtons slot="end">
              <IonButton routerLink={`/transfer/${match.params.id}`}>
                <IonIcon slot="icon-only" icon={arrowRedoSharp} />
              </IonButton>
            </IonButtons>
          ) : '' }
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonList>
            <IonItem>
              <IonLabel color="medium">Created At</IonLabel>
              <IonLabel>{createdAt}</IonLabel>
            </IonItem>
            <SolvedByComponent solvedBy={solvedBy} solvedAt={solvedAt}/>
            <IonItem>
              <IonLabel color="medium">Assigned To</IonLabel>
              <IonLabel>{assignedTo}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel color="medium">Assigned At</IonLabel>
              <IonLabel>{assignedAt}</IonLabel>
            </IonItem>
            <Tags tags={tags}/>
          </IonList>
        </IonCard>
        { assignedTos?.length > 1 ? (
          <IonCard>
            <IonCardContent>
              <h2>Assignment Note</h2>
              <IonText color="dark">{assignmentNote}</IonText>
            </IonCardContent>
          </IonCard>
        ) : '' }
        <NoteField solvedBy={solvedBy} isSolved={isSolved} value={ticketNoteValue}/>
        <CustomFields fields={fields} isSolved={isSolved} values={values}/>
      </IonContent>
      <IonToast position="bottom" color="danger" isOpen={!!error} message={error} duration={3000} onDidDismiss={() => setError('')} />
      {button}
    </IonPage>
  )
}

export default TicketDetail
