import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSearchbar,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  IonToast
} from '@ionic/react'
import { closeOutline, peopleOutline, personOutline } from 'ionicons/icons'
import React, { useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { Layan } from '../service/Layan'
import { LayanV2 } from '../service/LayanV2'
import { Group, User } from '../types'

interface PageProps extends RouteComponentProps<{
  id: string
}> {}

type GroupType = Group & { _id: string }
const TransferTicket: React.FC<PageProps> = ({ match }) => {
  const [ticket, setTicket] = useState<any>()
  const [groups, setGroups] = useState<GroupType[]>()
  const [agents, setAgents] = useState<any>()
  const [note, setNote] = useState<string>()
  const [error, setError] = useState<string>()
  const [assignTo, setAssignTo] = useState<any>()
  const [showModal, setShowModal] = useState<boolean>()

  const user = JSON.parse(localStorage.getItem('user')!)
  const history = useHistory()

  useIonViewDidEnter(async () => {
    const getTicket = await new Layan().getTicketDetails(match.params.id)
    setTicket(getTicket.data.ticket)
    const getGroups = await new LayanV2().getGroups()
    setGroups(getGroups.data.groups.map((group: Group) => ({...group, _id: group.mongoId})))
    const getAgents = await new LayanV2().getAgents({ isAvailable: true })
    setAgents(getAgents.data.users?.filter((u: User) => u.mongoId !== user._id && u.id !== user._id ).map((u: User) => ({ ...u, _id: String(u.mongoId || u.id) })))
  })

  const searching  = async (keyword?: string) => {
    if (!keyword) {
      const getGroups = await new LayanV2().getGroups()
      setGroups(getGroups.data.groups.map((group: Group) => ({...group, _id: group.mongoId})))
      const getAgents = await new LayanV2().getAgents({ isAvailable: true })
      setAgents(getAgents.data.users?.filter((u: User) => u.mongoId !== user._id && u.id !== user._id ).map((u: User) => ({ ...u, _id: String(u.mongoId || u.id) })))
    } else {
      setGroups(groups?.filter((group: any) => group.name.toLowerCase().includes(keyword.toLowerCase())))
      setAgents(agents?.filter((agent: any) => agent.name.toLowerCase().includes(keyword.toLowerCase())))
    }
  }

  const selectAssignTo = (data: any) => {
    setAssignTo(data)
    setShowModal(false)
  }

  const assign = async () => {
    if (!assignTo) {
      return setError('Please select a group or agent to assign the thread')
    }
    if (note) {
      await new Layan().updateTicket(ticket._id, { assignmentNote: note })
    }
    if (assignTo.type === 'group') {
      await new Layan().updateTicket(ticket._id, {
        queue: {
          groupId: assignTo.group._id
        }
      })
      await new Layan().requeue(ticket._id)
    } else {
      await new Layan().assign(ticket._id, assignTo.agent._id)
    }
    return history.replace('/main')
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"  />
          </IonButtons>
          <IonTitle>Transfer #{ticket?.number}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            <IonItem>
              <IonLabel position="floating">Assignment Note</IonLabel>
              <IonTextarea onIonChange={e => setNote(e.detail.value!)} value={note}></IonTextarea>
            </IonItem>
            <IonItem>
              <IonLabel>Assign To...</IonLabel>
              <IonButton fill="clear" onClick={() => setShowModal(true)}>{ (assignTo?.agent || assignTo?.group)?.name || 'Select' }</IonButton>
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <IonButton slot="bottom" expand="block" onClick={assign}>Transfer Thread</IonButton>
      <IonModal isOpen={showModal!}>
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonTitle>Select a Group or Agent</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={() => setShowModal(false)}><IonIcon icon={closeOutline} /></IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true}>
          <IonSearchbar onIonChange={e => searching(e.detail.value!)} />
          <IonList>
            { groups?.map((group: any) => (
              <IonItem key={group._id} button={true} onClick={() => selectAssignTo({ type: 'group', group })}>
                <IonIcon slot="start" icon={peopleOutline} />
                <IonLabel>{group.name}</IonLabel>
                <IonBadge>{group.userCount} people</IonBadge>
              </IonItem>
            )) }
            { agents?.map((agent: any) => (
              <IonItem key={agent._id} button={true} onClick={() => selectAssignTo({ type: 'agent', agent })}>
                <IonIcon slot="start" icon={personOutline} />
                <IonLabel>{agent.name}</IonLabel>
                <IonBadge color="success">{agent.load} threads</IonBadge>
              </IonItem>
            )) }
          </IonList>
        </IonContent>
      </IonModal>
      <IonToast position="bottom" color="danger" isOpen={!!error} message={error} duration={3000} onDidDismiss={() => setError('')} />
    </IonPage>
  )
}

export default TransferTicket
