import * as io from 'socket.io-client'

export class Socket {
  private static client: Socket | null

  private constructor(private socket: SocketIOClient.Socket) {}

  public static build(url: string, token: string) {
    if (!Socket.client) {
      Socket.client = new Socket(io.connect(url, { query: { token } }))
      Socket.client.socket.on('connect', () => {
        localStorage.setItem('socketId', Socket.client?.socket.id!)
      })
      Socket.client.socket.on('reconnect', () => {
        localStorage.setItem('socketId', Socket.client?.socket.id!)
      })
    }
    return Socket.client.socket
  }

  public static get io() {
    return Socket.client?.socket
  }

  public static destroy() {
    Socket.client = null
  }
}