import { IonBackButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react'
import React, { useState } from 'react'
import RoomItem from '../components/RoomItem'
import { Layan } from '../service/Layan'
import { Socket } from '../service/Socket'

const QueueRooms: React.FC = () => {
  const [rooms, setRooms] = useState<any[]>()

  useIonViewDidEnter(async () => {
    let rooms = await doRefresh()
    Socket.io?.on('new ticket created in queue', (data: any) => {
      rooms = [...rooms || [], data.room]
      setRooms(rooms)
    })
    Socket.io?.on('ticket assigned', (data: any) => {
      rooms = rooms?.filter((room: any) => room._id !== data.room._id)
      setRooms(rooms?.length ? rooms : null)
    })
  })

  useIonViewDidLeave(() => {
    Socket.io?.off('new ticket created in queue')
    Socket.io?.off('ticket assigned')
  })

  const doRefresh = async (e?: any) => {
    const resp = await new Layan().getQueueRooms()
    const rooms = resp.data.rooms
    setRooms(rooms)
    if (e) {
      document.querySelectorAll('ion-refresher')?.forEach(x => x.complete())
    }
    return rooms
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"  />
          </IonButtons>
          <IonTitle>Queue ({rooms?.length || 0})</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={e => doRefresh(e)}>
          <IonRefresherContent />
        </IonRefresher>
        { rooms?.length ? (
        <IonList>
          { rooms.map((room, i) => <RoomItem room={room} key={i} isInQueue={true} />) }
        </IonList>
      ) : (
        <div className="container">
          <img style={{ width: '75%', maxWidth: '379px' }} alt="empty" src={localStorage.getItem('theme') === 'dark' ? './assets/empty-queue-inverse.svg' : './assets/empty-queue.svg'} />
          <div style={{ padding: '0 20px 40px 20px' }}>
            <h2>No Conversations, yet.</h2>
            <p>No conversation in Layan, yet! Wait and always be prepared until someone need our help!</p>
          </div>
        </div> ) }
      </IonContent>
    </IonPage>
  )
}

export default QueueRooms
