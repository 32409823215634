import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonViewDidEnter,
  IonText
} from '@ionic/react'
import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import packagejson from '../../package.json'
import { Layan } from '../service/Layan'
import { Socket } from '../service/Socket'
import { User } from '../types'
import { LayanV2 } from '../service/LayanV2'

const Profile: React.FC = () => {
  const [user, setUser] = useState<User>()
  const [version, setVersion] = useState<any>()
  const [showAlertLogout, setShowAlertLogout] = useState<boolean>(false)

  const history = useHistory()

  useIonViewDidEnter(async () => {
    const user = await new LayanV2().getUser()
    setUser(user?.data.user)
    const version = await new Layan().getVersion()
    setVersion(version.data.version)
  })

  const changeTheme = () => {
    localStorage.getItem('theme') === 'dark' ? localStorage.removeItem('theme') : localStorage.setItem('theme', 'dark')
    localStorage.getItem('theme') === 'dark' ? document.body.classList.add('dark') : document.body.classList.remove('dark')
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"  />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{user?.name}</IonCardTitle>
            <IonCardSubtitle>
              <IonText color={user?.isAvailable ? 'success' : 'danger'}>⬤</IonText>&nbsp; {user?.status?.name || (user?.isAvailable ? 'Online' : 'Offline')}
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel color="medium">Username</IonLabel>
                <IonLabel>@{user?.username}</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel color="medium">Email</IonLabel>
                <IonLabel>{user?.email}</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel color="medium">Dark Theme</IonLabel>
                <IonLabel style={{ marginTop: '1px', marginBottom: 0 }}><IonToggle checked={localStorage.getItem('theme') === 'dark'} onIonChange={changeTheme} style={{ paddingLeft: 0 }} /></IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel color="medium">Sockets Connected</IonLabel>
                <IonLabel>{user?.sockets?.length || 0}</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel color="medium">Devices Connected</IonLabel>
                <IonLabel>{user?.firebases?.length || 0}</IonLabel>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
        <br />
        <div className="ion-text-center">
          <IonText color="medium">
            <small>v {packagejson.version}/{version}</small>
          </IonText>
        </div>
      </IonContent>
      <IonButton slot="bottom" expand="block" routerLink="/profile/edit">Edit Profile</IonButton>
      <IonButton color="danger" expand="block" slot="bottom" onClick={() => setShowAlertLogout(true)}>Logout</IonButton>
      <IonAlert
        isOpen={showAlertLogout}
        onDidDismiss={() => setShowAlertLogout(false)}
        header="Logout"
        message="Are you sure want to logout?"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Okay',
            handler: async () => {
              try {
                await new LayanV2().updateProfile({
                  removeFirebaseToken: [localStorage.getItem('firebaseToken')],
                  removeSocketId: [localStorage.getItem('socketId')]
                })
              } catch(error) {
                console.log('error on logout', error)
              }
              Cookies.remove('Authorization')
              Cookies.remove('RefreshToken')
              localStorage.removeItem('RefreshToken')
              localStorage.removeItem('Authorization')
              localStorage.removeItem('AccessToken')
              localStorage.removeItem('url')
              localStorage.removeItem('user')
              localStorage.removeItem('socketId')
              localStorage.removeItem('firebaseToken')
              Socket.destroy()
              history.replace('/login')
            }
          }
        ]} />
    </IonPage>
  )
}

export default Profile
