import { firebase } from '@firebase/app'
import '@firebase/messaging'
import config from '../config/config.json'
import { LayanV2 } from './LayanV2'

export class Firebase {

  public static run() {
    try {
      firebase.initializeApp(config.firebase)
      const messaging = firebase.messaging()
      messaging.usePublicVapidKey(config.firebaseVapidKey)
      Notification.requestPermission().then(() => {
        messaging.getToken().then(async token => {
          localStorage.setItem('firebaseToken', token)
          await new LayanV2().updateProfile({ addFirebaseToken: [token] })
        }).catch(() => console.log('No device found'))
        messaging.onTokenRefresh(async token => {
          localStorage.setItem('firebaseToken', token)
          await new LayanV2().updateProfile({ addFirebaseToken: [token] })
        })
        messaging.onMessage(payload => {
          navigator.serviceWorker.getRegistration().then(reg => {
            try {
              reg?.showNotification(payload.notification.title, {
                body: payload.notification.body
              })
            } catch (error) {}
          })
        })
      }).catch(err => console.error(err))
    } catch (err) {}
  }
}