import { IonCardHeader, IonCardTitle, IonCard, IonCardSubtitle } from '@ionic/react'
import React, { useState } from 'react'
import {DateParser} from'../utils/DateParser' 

interface ContainerProps {
  ticket: any
}


const Ticket: React.FC<ContainerProps> = ({ ticket }) => {
  const [number] = useState<number>(ticket.number)


  return (
    <IonCard routerLink={`/ticket/${ticket._id}`}>
      <IonCardHeader>
        <IonCardTitle>Ticket #{number}</IonCardTitle>
        <IonCardSubtitle>{DateParser(ticket.createdAt)}</IonCardSubtitle>
      </IonCardHeader>
    </IonCard>
  )
}

export default Ticket
