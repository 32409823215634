import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as Sentry from '@sentry/react'
import * as serviceWorker from './serviceWorker'
import { setupConfig } from '@ionic/react'

setupConfig({
  // swipeBackEnabled: false,
	// hardwareBackButton: false
})

Sentry.init({ dsn: 'https://ea043949f3e34bcfbc93b2967bc8e6e3@o375026.ingest.sentry.io/5318879' })

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register()