import { IonAvatar, IonItem, IonLabel, IonBadge } from '@ionic/react'
import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Layan } from '../service/Layan'

interface RoomProps {
  room: any,
  tab?: string,
  isInQueue?: boolean
}

const RoomItem: React.FC<RoomProps> = ({ room, tab, isInQueue }) => {
  const user = JSON.parse(localStorage.getItem('user')!)

  const history = useHistory()
  const [assigned, setAssigned] = useState<boolean>(false)

  const openRoom = () => {
    history.push(`/chat/${room._id || room.initiatedBy.phone}/${room._id ? 'roomId' : 'sourceId'}`)
  }

  const assign = async (ticketId: string) => {
    setAssigned(true)
    await new Layan().assign(ticketId, 'me', { new: true })
    history.push('/main')
  }

  const getColorBadge = () => {
    if (room.status === 'queue') {
      return 'warning'
    }
    if (room.status === 'solved') {
      return 'success'
    }
    if (room.status === 'open') {
      return 'danger'
    }
    if (room.status === 'bot') {
      return 'primary'
    }
  }

  const integrationMap = () => {
    if (room.bot?.integration === 'layanchat') {
      return 'Layan Chat'
    }
    if (room.bot?.integration === 'line') {
      return 'Line'
    }
    if (room.bot?.integration === 'telegram') {
      return 'Telegram'
    }
    if (room.bot?.integration === 'livechat') {
      return 'Live Chat'
    }
    if (room.bot?.integration === 'fb') {
      return 'Facebook'
    }
    if (room.bot?.integration === 'zendesk-chat-widget') {
      return 'Zendesk Chat'
    }
    return 'WhatsApp'
  }

  return (
    <IonItem button={true} disabled={assigned} onClick={() => isInQueue ? assign(room.latestTicket._id) : openRoom()}>
      <IonAvatar slot="start">
        <img src="./assets/avatar.svg" alt="avatar" />
      </IonAvatar>
      <div style={{ width: '97%', padding: tab === 'rooms' ? '5px 0' : '8px 0' }}>
        <IonLabel style={{ marginBottom: 0, marginTop: 0, width: '80%', paddingTop: '7px' }}>{room.initiatedBy?.name}</IonLabel>
        <div style={{ color: '#808289', margin: '6px 0 8px', fontSize: '12px', overflow: 'hidden' }}>
          { tab === 'rooms' ? (
            <>
              <IonBadge color="medium">{integrationMap()}</IonBadge>&nbsp;&nbsp;
              <IonBadge color={getColorBadge()}>{room.status}</IonBadge>
            </>
          ) : (
            <p style={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              { isInQueue ? moment(room.latestTicket.createdAt).fromNow() : (
                `${user._id === room.latestMessage?.user._id ? 'You: ' : ''}${(room.latestMessage?.content.text || room.latestMessage?.content.type)}`
              ) }
            </p>
          ) }
        </div>
      </div>
      {isInQueue ? '' : (
        <p style={{ color: '#92949c', position: 'absolute', right: '38px', top: '9px', fontSize: '10px' }}>
          {moment(room.updatedAt).calendar(null, { sameDay: 'HH:mm', lastDay: 'MMM D, HH:mm', lastWeek: 'MMM D, HH:mm', sameElse: 'MMM D, HH:mm' })}
        </p>
      )}
    </IonItem>
  )
}

export default RoomItem
