import {
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonSpinner,
  IonTextarea,
  IonToast,
  IonToolbar,
  IonPicker
} from '@ionic/react'
import { sendSharp, attachOutline, closeCircleOutline, flashOutline, happyOutline, readerOutline, returnUpBackOutline, pushOutline } from 'ionicons/icons'
import React, { useState, useEffect } from 'react'
import { Layan } from '../service/Layan'
import { LayanV2 } from '../service/LayanV2'
import { Picker } from 'emoji-mart'

import 'emoji-mart/css/emoji-mart.css'
import './Editor.css'

interface ContainerProps {
  room: any,
  ticketId: string,
  isCompact?: boolean,
  onReply: (message: any) => void,
  onChangeMode?: () => void
}

const Editor: React.FC<ContainerProps> = ({ room, ticketId, isCompact, onReply, onChangeMode }) => {
  const user = JSON.parse(localStorage.getItem('user')!)

  const [application, setApplication] = useState<any>()
  const [attachment, setAttachment] = useState<any>()
  const [attachmentUrl, setAttachmentUrl] = useState<string>()
  const [reply, setReply] = useState<string>()
  const [isPrivate, setIsPrivate] = useState<boolean>(room.latestTicket.solvedAt || room.assignedTo !== user._id)
  const [error, setError] = useState<string>()
  const [isSending, setIsSending] = useState<boolean>(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>()
  const [showPopoverMacros, setShowPopoverMacros] = useState<boolean>()
  const [showPopoverPushMessage, setShowPopoverPushMessage] = useState<boolean>()
  const [templateMessages, setTemplateMessages] = useState<any[]>()
  const [templatePushMessages, setTemplatePushMessages] = useState<any[]>()
  const [isFocusOnTextarea, setIsFocusOnTextarea] = useState<boolean>()

  if (!templateMessages && templateMessages !== []) {
    new LayanV2().getTemplateMessages().then(resp => setTemplateMessages(resp.data.templateMessages || []))
  }

  if (!templatePushMessages && templatePushMessages !== [] && ticketId) {
    new Layan().getTemplatePushMessage(ticketId).then(resp => setTemplatePushMessages(resp.data.templateMessages || []))
  }

  if (!application) {
    new LayanV2().getApplicationDetail().then(resp => setApplication(resp.data.application))
  }

  useEffect(() => {
    if (isCompact) {
      setShowEmojiPicker(false)
      setIsFocusOnTextarea(false)
    }
    onChangeMode?.()
  }, [isCompact, onChangeMode])

  const replyTicket = async () => {
    if (!reply?.replace(/\s/gi, '') && !attachment) {
      setReply('')
      return setError('Your reply can\'t be blank')
    }
    try {
      setIsSending(true)
      setReply(reply?.replace(/\n/gi, '\n'))
      document.querySelector('ion-textarea')?.setAttribute('disabled', 'disabled')
      const resp = await new Layan().replyTicket(room.latestTicket._id, {
        isPublic: !isPrivate,
        content: !attachment ? {
          type: 'text',
          text: reply
        } : {
          type: attachment.type.includes('image') ? 'image' : 'document',
          text: reply,
          attachment: {
            url: attachmentUrl,
            mimeType: attachment.type
          }
        }
      })
      onReply({
        ...resp.data.message,
        message: resp.data.message
      })
      setReply('')
      setAttachmentUrl('')
      setAttachment(null)
      setIsSending(false)
      setShowEmojiPicker(false)
      setIsFocusOnTextarea(false)
      document.querySelector('ion-textarea')?.removeAttribute('disabled')
      setTimeout(() => {
        document.querySelector(`.message-${resp.data.message._id}`)?.scrollIntoView()
      }, 500)
    } catch (error) {
      setError('Whoops, it\'s not your fault. Please try again.')
    }
  }

  const upload = async (e: any) => {
    const file = e.target.files?.[0]
    const element = document.querySelector('input.chooseFile') as HTMLInputElement
    if (element) {
      element.value = ''
    }

    setIsSending(true)
    setAttachment(file)
    setTimeout(() => document.querySelector('.bottom')?.scrollIntoView(), 200)
    const upload = await new Layan().upload(file)
    setAttachmentUrl(upload.data.file.location)
    setIsSending(false)
  }

  const cancelUpload = () => {
    setAttachmentUrl('')
    setAttachment(null)
    setIsSending(false)
  }

  const focusToTextarea = () => {
    setShowEmojiPicker(false)
    setIsFocusOnTextarea(true)
    setTimeout(() => document.querySelector('.bottom')?.scrollIntoView(), 300)
    setTimeout(() => document.querySelector('ion-textarea')?.setFocus(), 100)
  }

  const toggleInternalNote = () => {
    setIsPrivate(!isPrivate)
    setShowEmojiPicker(false)
    document.querySelector('ion-textarea')?.setFocus()
    setTimeout(() => document.querySelector('.bottom')?.scrollIntoView(), 200)
  }

  const showEmoji = () => {
    setShowEmojiPicker(!showEmojiPicker)
    setTimeout(() => document.querySelector('.bottom')?.scrollIntoView(), 200)
  }

  const showMacros = () => {
    setShowEmojiPicker(false)
    setShowPopoverMacros(true)
  }

  const showPushMessage = () => {
    setShowEmojiPicker(false)
    setShowPopoverPushMessage(true)
  }

  return (
    <IonFooter>
      { attachment ? (
        <IonItem>
          <IonLabel>{attachment.name}</IonLabel>
          <IonButtons slot="end">
            <IonButton onClick={cancelUpload}>
              <IonIcon slot="icon-only" icon={closeCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonItem>
      ) : '' }
      { isFocusOnTextarea ? (
        <IonItem color={isPrivate ? 'warning' : ''} lines="none">
          <IonTextarea autoGrow={true} rows={1} style={{ marginTop: 0, maxHeight: '9em' }} value={reply} placeholder={isPrivate ? 'Write in internal note...' : 'Write your reply...'} onIonChange={e => setReply(e.detail.value!)} onClick={focusToTextarea} />
        </IonItem>
      ) : '' }
      <IonToolbar color={isPrivate ? 'warning' : ''}>
        { !isFocusOnTextarea ? (
          <IonItem color={isPrivate ? 'warning' : ''} lines="none">
            <IonTextarea rows={1} style={{ marginTop: 0 }} value={reply} placeholder={isPrivate ? 'Write in internal note...' : 'Write your reply...'} onIonChange={e => setReply(e.detail.value!)} onClick={focusToTextarea} />
          </IonItem>
        ) : '' }
        { isFocusOnTextarea && !room.latestTicket.solvedAt && room.assignedTo === user._id ? (
          <IonButtons slot="start" style={{ marginLeft: '17px' }}>
            {/* <IonToggle checked={isPrivate} onIonChange={e => {
              setIsPrivate(e.detail.checked)
              setShowEmojiPicker(false)
              document.querySelector('ion-textarea')?.setFocus()
              setTimeout(() => document.querySelector('.bottom')?.scrollIntoView(), 200)
            }} />
            <IonLabel color="medium"><p>Internal note</p></IonLabel> */}
            <IonButton fill="clear" onClick={toggleInternalNote}>
              <IonIcon slot="start" icon={isPrivate ? returnUpBackOutline : readerOutline} />
              <IonLabel>{isPrivate ? 'Reply' : 'Note'}</IonLabel>
            </IonButton>
          </IonButtons>
        ) : '' }
        <IonButtons slot="end" style={{ marginRight: '17px' }}>
          { isFocusOnTextarea ? (
            <>
              { application?.plugins?.templatePushMessage?.isEnabled && templatePushMessages?.length ? (
                <IonButton onClick={showPushMessage}>
                  <IonIcon icon={pushOutline} slot="icon-only" />
                </IonButton>
              ) : '' }
              <IonButton onClick={showEmoji}>
                <IonIcon icon={happyOutline} slot="icon-only" />
              </IonButton>
            </>
          ) : '' }
          <IonButton onClick={() => (document.querySelector('input.chooseFile') as HTMLElement)?.click()}>
            <IonIcon icon={attachOutline} slot="icon-only" />
            <input type="file" className="chooseFile" style={{ display: 'none' }} onChange={e => upload(e)} />
          </IonButton>
          { templateMessages?.length ? (
            <IonButton onClick={showMacros}>
              <IonIcon icon={flashOutline} slot="icon-only" />
            </IonButton>
          ) : '' }
          <IonButton disabled={isSending || (!reply?.replace(/\s/gi, '') && !attachment)} className="send" slot="end" color="primary" fill="solid" onClick={replyTicket}>
            { isSending ? <IonSpinner /> : <IonIcon icon={sendSharp} /> }
          </IonButton>
        </IonButtons>
      </IonToolbar>
      { showEmojiPicker ? (
        <IonItem lines="none" className="ion-no-padding" style={{ '--inner-padding-end': 0 }}>
          <Picker theme={localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'} native={true} useButton={false} onSelect={e => setReply(`${reply}${(e as any).native}`)} style={{ width: '100%' }} />
        </IonItem>
      ) : '' }
      <IonToast color="danger" isOpen={!!error} message={error} duration={600} onDidDismiss={() => setError('')} />
      <IonPicker isOpen={showPopoverMacros!} onDidDismiss={() => setShowPopoverMacros(false)} columns={[
        {
          name: 'macros',
          options: templateMessages?.map(message => {
            return {
              text: message.message,
              value: message.title
            }
          }) || []
        }
      ]} buttons={[
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: value => {
            setReply(`${reply}${value.macros.text} `)
            setShowPopoverMacros(false)
          }
        }
      ]} />
      <IonPicker isOpen={showPopoverPushMessage!} onDidDismiss={() => setShowPopoverPushMessage(false)} columns={[
        {
          name: 'pushMessage',
          options: templatePushMessages?.map(message => {
            return {
              text: message.message,
              value: message
            }
          }) || []
        }
      ]} buttons={[
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: value => {
            new Layan().pushMessage(room._id, value.pushMessage.value).then(() => {
              setShowPopoverPushMessage(false)
            })
          }
        }
      ]} />
    </IonFooter>
  )
}

export default Editor
