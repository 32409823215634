import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonPopover,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter,
  IonSelect,
  IonSelectOption,
  IonToast,
  useIonViewDidLeave,
  createGesture,
  IonFooter
} from '@ionic/react'
import { ellipsisVerticalSharp, personOutline, cloudOfflineOutline, cloudDoneOutline } from 'ionicons/icons'
import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import RoomItem from '../components/RoomItem'
import { Layan } from '../service/Layan'
import { Socket } from '../service/Socket'
import './Main.css'
import { LayanV2 } from '../service/LayanV2'
import { User } from '../types'

type UserType = User & {
  _id: string,
  groupId: string[]
}
const Main: React.FC = () => {
  const [tab, setTab] = useState<string>('chat')
  const [rooms, setRooms] = useState<any[]>()
  const [queue, setQueue] = useState<number>(0)
  const [user, setUser] = useState<UserType>()
  const [application, setApplication] = useState<any>()
  const [statuses, setStatuses] = useState<any[]>()
  const [isReload, setIsReload] = useState<boolean>(true)
  const [index, setIndex] = useState<number>(0)
  const [showPopover, setShowPopover] = useState<{ open: boolean, event?: Event }>()
  const [error, setError] = useState<string>()

  const history = useHistory()

  useIonViewWillEnter(async () => {
    const url = localStorage.getItem('url')
    console.log(url)
    if (!url) {
      Cookies.remove('Authorization')
      history.replace('/login')
    }
    const resp: { data?: { user: User } } = await new LayanV2().getUser()
    if (resp.data?.user) {
      const user = resp.data.user
      setUser({...user, _id: String(user.mongoId || user.id), groupId: user.agentGroups.filter(ag => ag.group).map(ag => String(ag.group?.mongoId || ag.group?.id)) })
    }
    
  })

  useIonViewDidEnter(async () => {
    const getApplication = await new LayanV2().getApplicationDetail()
    setApplication(getApplication.data.application)

    const getStatuses = await new LayanV2().getStatuses()
    setStatuses([
      {
        isAvailable: true,
        name: 'Online'
      },
      ...getStatuses.data.statuses || [],
      {
        isAvailable: false,
        name: 'Offline'
      }
    ])

    const tabSelected: string = document.querySelector('ion-segment')?.value!
    let rooms = await changeTab(tabSelected)

    const respQueue = await new Layan().getQueueRooms()
    let queueLength = respQueue.data.rooms?.length
    setQueue(queueLength)

    Socket.io?.on('ticket assigned', (data: any) => {
      const user = JSON.parse(localStorage.getItem('user')!)
      if (tab === 'chat' && data.room.assignedTo === user._id && !rooms?.find((room: any) => room._id === data.room._id)) {
        rooms = [data.room, ...rooms || []].sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
        setRooms(rooms)
      }
    })
    Socket.io?.on('ticket solved', (data: any) => {
      if (tab === 'chat' && rooms?.find((room: any) => room._id === data.ticket.roomId)) {
        rooms = rooms?.filter((room: any) => room._id !== data.ticket.roomId)
        setRooms(rooms?.length ? rooms : null)
      }
      if (!data.ticket.assignedTo?._id && (!data.ticket.queue?.groupId || user?.groupId?.includes(data.ticket.queue?.groupId))) {
        queueLength =- 1
        setQueue(queueLength)
      }
    })
    Socket.io?.on('message', (data: any) => {
      if (tab === 'chat' && rooms?.find((room: any) => room._id === data.message.ticket.roomId)) {
        const { ticket, ...message } = data.message
        const { room, ...latestTicket } = ticket
        rooms = [
          { ...room, latestTicket, latestMessage: message },
          ...rooms?.filter((room: any) => room._id !== data.message.ticket.roomId) || []
        ].sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
        setRooms(rooms)
      }
    })
    // Socket.io?.on('new ticket created in queue', ({ room }) => {
    //   if (!room.latestTicket.queue?.groupId || user?.groupId?.includes(room.latestTicket.queue?.groupId)) {
    //     queueLength =+ 1
    //     setQueue(queueLength)
    //   }
    // })
    // Socket.io?.on('ticket assigned', ({ room }) => {
    //   if (!room.latestTicket.queue?.groupId || user?.groupId?.includes(room.latestTicket.queue?.groupId)) {
    //     queueLength =- 1
    //     setQueue(queueLength)
    //   }
    // })

    let activeTab: string = tabSelected
    createGesture({
      el: document.querySelector('ion-content')!,
      threshold: 15,
      direction: 'x',
      gestureName: 'swipe-to-move-tab',
      onMove: detail => {
        if (detail.deltaX < 0 && activeTab === 'chat') {
          activeTab = 'rooms'
          changeTab(activeTab)
        } else if (detail.deltaX > 0 && activeTab === 'rooms') {
          activeTab = 'chat'
          changeTab(activeTab)
        }
      }
    }).enable()
  })

  useIonViewDidLeave(() => {
    setIndex(0)
    setRooms([])
    Socket.io?.off('ticket assigned')
    Socket.io?.off('ticket solved')
    Socket.io?.off('message')
    Socket.io?.off('new ticket created in queue')
    Socket.io?.off('ticket assigned')
  })

  const doRefresh = async (selected: string = tab, index: number = 0) => {
    let results: any = []
    if (selected === 'chat') {
      const resp = await new Layan().getActiveRooms()
      results = resp.data.rooms.sort((a: any, b: any) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
      setRooms(results)
    } else {
      const resp = await new Layan().getRooms(index, 12)
      setIndex(index + 12)
      results = [
        ...index && rooms?.length ? rooms : [], ...resp?.data?.rooms.map((r: any) => {
          return {
            ...r.room,
            initiatedBy: {
              phone: r.sourceId,
              ...r.room.initiatedBy,
              name: r.name
            },
            status: r.status
          }
        }) || []
      ]
      setRooms(results)

      document.querySelector('ion-infinite-scroll')?.complete()
      setIsReload(resp?.data?.rooms?.length >= 12)
    }
    document.querySelector('ion-refresher')?.complete()
    return results
  }

  const changeTab = async (tab: string) => {
    setTab(tab)
    const data = await doRefresh(tab)
    return data
  }

  const getNextRooms = async () => {
    await new Promise(res => setTimeout(res, 500))
    await doRefresh(tab, index)
  }

  const getStatus = () => {
    if (user?.status) {
      return statuses?.find(status => status.name === user?.status.name)
    }
    return {
      isAvailable: user?.isAvailable,
      name: user?.isAvailable ? 'Online' : 'Offline'
    }
  }

  const changeStatus = async (status: any) => {
    const updatedObject = { isAvailable: status?.isAvailable, status: status?.name || null }
    try {
      await new LayanV2().updateProfile(updatedObject)
      if (user) setUser({ ...user, isAvailable: status?.isAvailable , status: { name: status?.name } })
    } catch (error) {
      setError(error.response?.data?.error?.message || error.message)
    }
    setShowPopover({ open: false })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <img src="/assets/icon.svg" alt="icon" style={{ width: '30px', marginRight: '13px', position: 'relative', top: '2px' }} />
            <IonLabel style={{ position: 'relative', top: '-6px' }}>{application?.title || 'Layan.io'}</IonLabel>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={e => setShowPopover({ open: true, event: e.nativeEvent })}>
              <IonIcon slot="icon-only" icon={ellipsisVerticalSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSegment scrollable={true} value={tab} onIonChange={e => changeTab(e.detail.value!)}>
            <IonSegmentButton value="chat">
              <IonLabel>Active Chat</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="rooms">
              <IonLabel>All Rooms</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={() => doRefresh(tab)}>
          <IonRefresherContent />
        </IonRefresher>
        { rooms?.length ? (
          <IonList>
            { rooms.map((room, i) => <RoomItem tab={tab} room={room} key={i} />) }
            { tab === 'rooms' && isReload ? (
              <IonInfiniteScroll position="bottom" onIonInfinite={getNextRooms}>
                <IonInfiniteScrollContent />
              </IonInfiniteScroll>
            ) : '' }
          </IonList>
        ) : (
          <div className="container">
            { queue > 0 ? (
              <>
                <img style={{ width: '75%', maxWidth: '401px' }} alt="empty" src={localStorage.getItem('theme') === 'dark' ? './assets/waiting-inverse.svg' : './assets/waiting.svg'} />
                <div style={{ padding: '0 20px 40px 20px' }}>
                  <h2>Someone Waiting for You!</h2>
                  <p>Check Queue List to help our customers.</p>
                </div>
              </>
            ) : (
              <>
                <img style={{ width: '75%', maxWidth: '409px' }} alt="empty" src={localStorage.getItem('theme') === 'dark' ? './assets/nodata-inverse.svg' : './assets/nodata.svg'} />
                <div style={{ padding: '0 20px 40px 20px' }}>
                  <h2>No Conversations, yet.</h2>
                  <p>No conversation in Layan, yet! Wait and always be prepared until someone need our help!</p>
                </div>
              </>
            ) }
          </div>
        ) }
      </IonContent>
      {/* {queue > 0 ? <IonFooter><IonButton expand="block" routerLink="/queue">{queue} Customers in Queue</IonButton></IonFooter> : ''} */}
      <IonFooter><IonButton expand="block" routerLink="/queue">Show Queue</IonButton></IonFooter>
      <IonPopover event={showPopover?.event} isOpen={showPopover?.open!} onDidDismiss={() => setShowPopover({ open: false })}>
        <IonList style={{ padding: 0 }}>
          <IonItem button={true} routerLink="/profile" onClick={() => setShowPopover({ open: false })}>
            <IonIcon icon={personOutline} slot="start" />
            <IonLabel>My Profile</IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={user?.isAvailable ? cloudDoneOutline : cloudOfflineOutline} slot="start" />
            <IonSelect style={{ paddingLeft: 0 }} interface="popover" compareWith={(a, b) => a && b && a.isAvailable === b.isAvailable && a.name === b.name} value={getStatus()} onIonChange={e => changeStatus(e.detail.value!)}>
              { statuses?.map((status, i) => <IonSelectOption key={i} value={status}>{status.name || (status.isAvailable ? 'Online' : 'Offline')}</IonSelectOption>) }
            </IonSelect>
          </IonItem>
        </IonList>
      </IonPopover>
      <IonToast position="bottom" color="danger" isOpen={!!error} message={error} duration={3000} onDidDismiss={() => setError('')} />
    </IonPage>
  )
}

export default Main
