import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonBackButton, IonButtons, useIonViewDidEnter, IonCard, IonList, IonItem, IonLabel, IonTextarea, IonIcon } from '@ionic/react'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { Layan } from '../service/Layan'
import Ticket from '../components/Ticket'
import { sendSharp }from 'ionicons/icons'

interface PageProps extends RouteComponentProps<{
  id: string,
  isRoomId?: string
}> {}

const RoomDetail: React.FC<PageProps> = ({ match }) => {
  const [customerName, setCustomerName] = useState<string>("")
  const [customerPhone, setCustomerPhone] = useState<string>("")
  const [customerId, setCustomerId] = useState<string>("")
  const [tickets, setTickets] = useState<any>()
  const [customerNote, setCustomerNote] = useState<string>()
  const [index, setIndex] = useState<number>(4)
  const [isNoResult, setIsNoResult] = useState<boolean>(false)

  const Tickets = (props: any) => {
    return props.tickets === undefined ? null :
    props.tickets.map(
      (ticket: any) => {
        return <Ticket ticket={ticket} key={ticket._id}/>
      }
    )
  }

  const fetchTickets = async () => {
    try {
      const roomId = match.params.id
      const getTickets = await new Layan().getTickets(roomId!, index, 4)
      const { data } = getTickets
      const { room } = data

      setIndex(index + 4)

      setTickets(tickets.concat(room.tickets))
      setIsNoResult(!room.tickets?.length)
    } catch (error) {
      // ignore
    }
  }

  const Submit = async () => {
    try {
      const toSubmit = {
        description: customerNote
      }
      const res = await new Layan().createUpdateCustomerNote(customerId, toSubmit)
      console.log(res)
    } catch(e) {
      console.error(e)
    }
  }

  const onSaveText = value => {
    setCustomerNote(value)
  }


  useIonViewDidEnter(async () => {
    let room: any = null
    try {
      if (match.params.isRoomId === 'roomId') {
        const roomDetailsLoaded = await new Layan().getTickets(match.params.id!, 0, 4)
        const { data } = roomDetailsLoaded
        room = data.room
        setCustomerName(room.initiatedBy.name)
        setCustomerPhone(room.initiatedBy.phone)
        setCustomerId(room.initiatedBy._id)
        setTickets(room.tickets)
      } else if (match.params.isRoomId === 'sourceId') {
        const getRooms = await new Layan().getRoomWithSourceId(match.params.id)
        room = getRooms.data.rooms[0]
        setCustomerName(getRooms.data.rooms?.[0].name)
        setCustomerPhone(getRooms.data.rooms?.[0].sourceId)
      }
    } catch (error) {
      const getRooms = await new Layan().getRoomWithSourceId(match.params.id)
      room = getRooms.data.rooms[0]
      setCustomerName(getRooms.data.rooms?.[0].name)
      setCustomerPhone(getRooms.data.rooms?.[0].sourceId)
    }

    try {
      const customerNoteLoaded = await new Layan().getCustomerNote(room.initiatedBy._id)
      const { data } = customerNoteLoaded
      const { customerNote } = data
      setCustomerNote(customerNote.description)
    } catch (e) {
      setCustomerNote("")
    }

    if (room.tickets?.length < 4) {
      setIsNoResult(true)
    }
  })

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main" />
          </IonButtons>
          <IonTitle>{customerName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonList>
            <IonItem>
              <IonLabel color="medium">Customer Name</IonLabel>
              <IonLabel>{customerName}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel color="medium">Phone Number</IonLabel>
              <IonLabel>{customerPhone}</IonLabel>
            </IonItem>
          </IonList>
        </IonCard>
        <IonCard>
          <IonItem>
            <IonLabel color="medium" position="floating">Customer Note</IonLabel>
            <IonTextarea value={customerNote} onIonChange={e => onSaveText(e.detail.value!)}></IonTextarea>
            <IonToolbar>
              <IonButton slot="end" fill="clear" onClick={Submit}>
                <IonIcon slot="icon-only" icon={sendSharp} />
              </IonButton>
            </IonToolbar>
          </IonItem>
        </IonCard>
        { tickets?.length ? (
          <IonList>
            <IonItem><IonLabel>Ticket History</IonLabel></IonItem>
            <Tickets tickets={tickets}/>
          </IonList>
        ) : '' }
        {tickets?.length && !isNoResult ? <IonButton expand="full" size="default" autoCapitalize="off" fill="clear" onClick={fetchTickets}>Click to load more...</IonButton> : ''}
      </IonContent>
    </IonPage>
  )
}

export default RoomDetail
