import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading, IonPage,
  IonPopover,
  IonTitle,
  IonToast,
  IonToolbar,
  IonModal,
  useIonViewDidEnter,
  useIonViewDidLeave
} from '@ionic/react'
import 'emoji-mart/css/emoji-mart.css'
import { checkmarkOutline, ellipsisVerticalSharp, listOutline, personOutline } from 'ionicons/icons'
import moment from 'moment'
import React, { useState } from 'react'
import TransferDialogue from '../components/TransferDialogue'
import { RouteComponentProps, useHistory } from 'react-router'
import ChatBubble from '../components/ChatBubble'
import Editor from '../components/Editor'
import { Layan } from '../service/Layan'
import { Socket } from '../service/Socket'

interface PageProps extends RouteComponentProps<{ id: string, isRoomId?: string }> {}

const RoomChat: React.FC<PageProps> = ({ match }) => {
  const [room, setRoom] = useState<any>()
  const [customer, setCustomer] = useState<any>()
  const [conversations, setConversations] = useState<any[]>()
  const [messages, setMessages] = useState<any[]>()
  const [index, setIndex] = useState<number>(0)
  const [error, setError] = useState<string>()
  const [showPopover, setShowPopover] = useState<{ open: boolean, event?: Event }>()
  const [showTransfer, setShowTransfer] = useState<boolean>(false)
  const [showAlertSolve, setShowAlertSolve] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isNoResult, setIsNoResult] = useState<boolean>(false)
  const [isCompactEditor, setIsCompactEditor] = useState<boolean>(false)

  const user = JSON.parse(localStorage.getItem('user')!)
  const history = useHistory()

  const fetchConversations = async (): Promise<any[]> => {
    const roomId = match.params.id
    const isUseRoomId = match.params.isRoomId === 'roomId'
    let getConversations: any
    if (isUseRoomId) {
      try {
        getConversations = await new Layan().getConversations(roomId!, index, 20)
      } catch (error) {
        getConversations = await new Layan().getConversationsWithSourceId(roomId!, index, 20)
      }  
    } else {
      getConversations = await new Layan().getConversationsWithSourceId(roomId!, index, 20)
    }
    

    const { customer, conversations } = getConversations.data
    if (!index) {
      setCustomer(customer)
    }

    setIndex(index + 20)
    return conversations
  }

  useIonViewDidEnter(async () => {
    setLoading(true)
    const convs = await fetchConversations()
    setConversations(convs)
    const getRoomBySourceId = async () => {
      getRoom = await new Layan().getRoomWithSourceId(match.params.id)
      getRoom = {
        ...getRoom,
        data: {
          ...getRoom.data,
          room: {
            initiatedBy: getRoom.data.rooms?.[0],
            latestTicket: {
              solvedAt: null
            }
          }
        }
      }
      setRoom(getRoom.data.room)
    }

    let getRoom: any
    try {
      if (match.params.isRoomId === 'roomId') {
        getRoom = await new Layan().getRoom(match.params.id)
        setRoom(getRoom.data.room)
      } else if (match.params.isRoomId === 'sourceId') {
        getRoomBySourceId()
      }
    } catch (error) {
      getRoomBySourceId()
      // setRoom({
      //   initiatedBy: getRoom.data.rooms?.[0],
      //   latestTicket: {
      //     solvedAt: null
      //   }
      // })
    }

    await new Promise(res => setTimeout(res, 1000))
    document.querySelector('.bottom')?.scrollIntoView()
    setLoading(false)

    const incommingMessages: any[] = []
    Socket.io?.on('message', (data: any) => {
      if (data.message.ticketId === getRoom?.data.room.latestTicket._id) {
        const parser = {
          ...data.message,
          message: data.message
        }
        setMessages([...incommingMessages, parser])
        incommingMessages.push(parser)
        setTimeout(() => {
          document.querySelector(`.message-${data.message._id}`)?.scrollIntoView()
        }, 500)
      }
    })
    Socket.io?.on('ticket solved', (data: any) => {
      if (data.ticket.roomId === match.params.id) {
        history.replace('/main')
      }
    })
  })

  useIonViewDidLeave(() => {
    Socket.io?.off('message')
    Socket.io?.off('ticket solved')
    setMessages([])
  })

  const getNextConversations = async () => {
    setLoading(true)
    const lastMessageId = conversationsSorted(conversations)?.find(conv => conv.type === 'message')?.message._id
    const nextConvs = await fetchConversations()
    document.querySelector('ion-infinite-scroll')?.complete()
    setConversations(Array.from(new Set([...conversations || [], ...nextConvs || []])))
    setTimeout(() => {
      document.querySelector(`.message-${lastMessageId}`)?.scrollIntoView()
      setLoading(false)
    }, 300)
    setIsNoResult(!nextConvs?.length)
  }

  const conversationsSorted = (conversations?: any[]) => {
    return conversations?.sort((a, b) => {
      const compareTime = new Date((a.message || a.divider).createdAt).getTime() - new Date((b.message || b.divider).createdAt).getTime()
      if (compareTime !== 0) {
        return compareTime
      }
      if (a.type !== b.type) {
        return a.type === 'divider' ? -1 : 1
      }
      return a.message?._id.toString().localeCompare(b.message?._id.toString())
    })
  }

  return (
    <IonPage>
      <IonModal isOpen={showTransfer} onDidDismiss={() => setShowTransfer(false)}>
        <TransferDialogue setShowTransfer={setShowTransfer}/>
      </IonModal>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main" />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}>
              <IonIcon icon={ellipsisVerticalSharp} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>{room?.initiatedBy.name || customer?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent onClick={() => setIsCompactEditor(true)}>
        { !isNoResult ? <IonButton expand="full" size="default" autoCapitalize="off" fill="clear" onClick={getNextConversations}>Click to load more...</IonButton> : '' }
        <IonList style={{ minHeight: '93vh' }}>
          { conversationsSorted(conversations)?.map(
            (conversation, i) => conversation.type === 'message' ? (
              <ChatBubble message={conversation.message} key={i} />
            ) : conversation.divider.type === 'ticketStarted' ?
            (
              <IonItem key={i} lines="full" button={true} routerLink={`/ticket/${conversation.divider.ticket._id}`} className={`ticket-${conversation.divider.ticket._id}`} style={{ marginBottom: '10px', marginTop: '10px' }}>
                <IonLabel slot="start">Ticket #{conversation.divider.ticket.number}</IonLabel>
                <IonButton slot="end" fill="outline" size="small">
                  <IonLabel>See Details</IonLabel>
                </IonButton>
              </IonItem>
            ) : conversation.divider.type === 'ticketEnded' ?
            (
              <IonItemDivider key={i} style={{ marginBottom: '10px', marginTop: '10px' }}>
                <IonLabel style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>Ticket #{conversation.divider.ticket.number} solved at {moment(conversation.divider.ticket.solvedAt).format('MMM D, HH:mm')}</IonLabel>
              </IonItemDivider>
            ) : ''
          ) }
          { messages?.map((message, i) => (
            <ChatBubble message={message} key={i + (conversations?.length || 0)} />
          )) }
        </IonList>
        <IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message='Loading...' />
        <div className="bottom" />
      </IonContent>
      {/* { room && !room.latestTicket.solvedAt && room.assignedTo === user._id ? <Editor room={room} ticketId={room?.latestTicket._id} isCompact={isCompactEditor} onReply={(message: any) => setMessages([...messages || [], message])} onChangeMode={() => setIsCompactEditor(false)} /> : '' } */}
      { room ? <Editor room={room} ticketId={room?.latestTicket._id} isCompact={isCompactEditor} onReply={(message: any) => setMessages([...messages || [], message])} onChangeMode={() => setIsCompactEditor(false)} /> : '' }
      <IonToast color="danger" isOpen={!!error} message={error} duration={600} onDidDismiss={() => setError('')} />
      <IonPopover event={showPopover?.event} isOpen={showPopover?.open!} onDidDismiss={() => setShowPopover({ open: false })}>
        <IonList style={{ padding: 0 }}>
          <IonItem button={true} routerLink={`/room/${match.params.id}/${match.params.isRoomId ? match.params.isRoomId : ''}`} onClick={() => setShowPopover({ open: false })}>
            <IonIcon icon={personOutline} slot="start" />
            <IonLabel>Room Details</IonLabel>
          </IonItem>
          { room ? (
            <>
              <IonItem button={true} routerLink={`/ticket/${room?.latestTicket._id}`} onClick={() => setShowPopover({ open: false })}>
                <IonIcon icon={listOutline} slot="start" />
                <IonLabel>Latest Ticket Details</IonLabel>
              </IonItem>
              { !room?.latestTicket.solvedAt && room.assignedTo === user._id ? (
                <IonItem button={true} color="success" onClick={() => {
                  setShowPopover({ open: false })
                  setShowAlertSolve(true)
                }}>
                  <IonIcon icon={checkmarkOutline} slot="start" />
                  <IonLabel>Solve</IonLabel>
                </IonItem>
              ) : '' }
            </>
          ) : '' }
        </IonList>
      </IonPopover>
      <IonAlert
        isOpen={showAlertSolve}
        onDidDismiss={() => setShowAlertSolve(false)}
        header="Solve Ticket"
        message={`Are you sure want to solve ticket #${room?.latestTicket.number}?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Okay',
            handler: async () => {
              try {
                await new Layan().solve(room?.latestTicket._id)
                history.replace('/main')
              } catch (error) {
                setError(error.response?.data?.error?.message || 'Something error')
              }
            }
          }
        ]} />
    </IonPage>
  )
}

export default RoomChat
