import { IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonText, IonToast } from '@ionic/react'
import { documentOutline, micOutline, videocamOutline } from 'ionicons/icons'
import moment from 'moment'
import { basename } from 'path'
import React, { useState } from 'react'
import { defaultParser } from '../utils/MessageParser'
import './ChatBubble.css'

interface ContainerProps {
  message: any
}

const ChatBubble: React.FC<ContainerProps> = ({ message }) => {
  const [touched, setTouched] = useState<any>()
  const [info, setInfo] = useState<string>()

  const user = JSON.parse(localStorage.getItem('user')!)

  const getColour = (message: any): string => {
    if ((!message.message.isPublic && message.ticketId) || message.user.role === 'bot') {
      return 'warning'
    }
    if (isInRight()) {
      return 'primary'
    }
    return 'dark'
  }

  const getNameColour = (message: any): string => {
    if ((!message.message.isPublic && message.ticketId) || message.user.role === 'bot') {
      return 'danger'
    }
    if (isInRight()) {
      return localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
    }
    return localStorage.getItem('theme') === 'dark' ? 'danger' : 'warning'
  }

  const isInRight = () => message.user.role === 'agent' || message.user.role === 'admin'

  const getSpeechClass = (message: any) => {
    if ((!message.message.isPublic && message.ticketId) || message.user.role === 'bot') {
      if (isInRight()) {
        return `speech-${getColour(message)}-right`
      }
      return `speech-${getColour(message)}-left`
    }
    return `speech-${getColour(message)}`
  }

  const copyToClipboard = () => {
    if (navigator.clipboard && message.message.content.text) {
      navigator.clipboard.writeText(message.message.content.text)
      setInfo('Message copied successfully!')
    }
  }

  return (
    <IonItem className={getSpeechClass(message) + ' message-' + message._id} lines="none">
      <IonCard style={{ maxWidth: '70%', minWidth: message.message.content.type === 'text' || message.message.content.type === 'location' ? '60px' : '70%', margin: '5px' }} color={getColour(message)} slot={isInRight() ? 'end' : 'start'} onTouchStart={() => setTouched(setTimeout(copyToClipboard, 1500))} onTouchEnd={() => clearTimeout(touched)}>
        <IonCardContent style={{ padding: '7px 10px' }}>
          { user._id !== message.user._id || 'agent' !== message.user.role ? <IonLabel color={getNameColour(message)} style={{ fontSize: '12px' }}>{message.user.name}</IonLabel> : '' }
          { message.message.content.type !== 'text' && message.message.content.type !== 'location' ? (
            <div style={{ marginTop: '8px' }}>
              { message.message.content.type === 'image' ? (
                <a href={message.message.content.attachment.url} target="_blank" rel="noopener noreferrer">
                  {/* <img src={message.message.content.attachment.url} alt="message" /> */}
                  <div style={{ backgroundImage: `url(${message.message.content.attachment.url})`, backgroundSize: 'cover', width: 'auto', height: '220px', backgroundPosition: 'center' }} />
                </a>
              ) : (
                <IonItem button={true} href={message.message.content.attachment.url} target="_blank">
                  <IonLabel>{decodeURI(basename(message.message.content.attachment.url))}</IonLabel>
                  { message.message.content.type === 'document' ? <IonIcon icon={documentOutline} slot="end" /> : '' }
                  { message.message.content.type === 'voice' ? <IonIcon icon={micOutline} slot="end" /> : '' }
                  { message.message.content.type === 'video' ? <IonIcon icon={videocamOutline} slot="end" /> : '' }
                </IonItem>
              ) }
            </div>
          ) : '' }
          { message.message.content.text ? <p dangerouslySetInnerHTML={{__html: defaultParser(message.message.content.text).replace(/\n/gi, '<br />')}} style={{ marginTop: '3px' }} /> : <div style={{ marginBottom: '7px' }} /> }
        </IonCardContent>
      </IonCard>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <IonText color="medium">
          <p style={{
            ...isInRight() ? { right: 0 } : { left: 0 },
            position: 'absolute',
            bottom: 0,
            marginBottom: '7px',
            fontSize: '11px',
            textAlign: isInRight() ? 'right' : 'left' }}>
              {moment(message.createdAt).calendar(null, { sameDay: 'HH:mm', lastDay: 'MMM D, HH:mm', lastWeek: 'MMM D, HH:mm', sameElse: 'MMM D, HH:mm' })}
          </p>
        </IonText>
      </div>
      <IonToast color="primary" isOpen={!!info} message={info} duration={1000} onDidDismiss={() => setInfo('')} />
    </IonItem>
  )
}

export default ChatBubble
