// WhatsApp Formatting Parse by sankalp179
// https://github.com/sankalp179/whatsapp-formatter/blob/master/index.html
// Reused with some modification

function isAplhanumeric(c: string){
  const x = c.charCodeAt(0)
  return ((x >= 65 && x <= 90) || (x >= 97 && x <= 122) || (x >= 48 && x <= 57))
}
function whatsAppStyleParser(format,wildcard, opTag, clTag) {
  const indices: number[] = []
  for (let i = 0; i < format.length; i++) {
    if (format[i] === wildcard) {
      if (indices.length % 2) {
        if (format[i - 1] !== ' ') {
          if (typeof(format[i + 1]) === 'undefined' || !isAplhanumeric(format[i+1])) {
            indices.push(i)
          }
        }
      } else {
        if (typeof(format[i + 1]) !== 'undefined') {
          if (format[i + 1] !== ' ') {
            if (typeof(format[i - 1]) === 'undefined' || !isAplhanumeric(format[i-1])) {
              indices.push(i)
            }
          }
        }
      }
    } else {
      if (format[i].charCodeAt() === 10 && indices.length % 2) {
        indices.pop()
      }
    }
  }
  if (indices.length % 2) {
    indices.pop()
  }
  let e: number = 0
  indices.forEach((v,i) => {
    const t = i % 2 ? clTag : opTag
    v += e
    format = format.substr(0, v) + t + format.substr(v + 1)
    e += t.length - 1
  })
  return format
}

export function defaultParser(message: string) {
  let result = message
  result = whatsAppStyleParser(result, '*', '<b>','</b>')
  result = whatsAppStyleParser(result, '_', '<i>','</i>')
  result = whatsAppStyleParser(result, '~', '<del>','</del>')
  result = whatsAppStyleParser(result, '```', '<pre>','</pre>')

  return result
}