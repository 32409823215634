import Axios from 'axios'
import Cookies from 'js-cookie'
import { Layan } from './Layan'
import config from '../config/config.json'
import { Socket } from '../service/Socket'

export class LayanV2 {
  public constructor(private url: string = `${config.url}/api/v2${localStorage.getItem('application') ? `/${localStorage.getItem('application')}` : ''}`!) {}

  public async getApplication(name: string) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/${name}/application`, {
        timeout: 15000,
        headers: {
          authorization: localStorage.getItem('Authorization') ? `Bearer ${localStorage.getItem('Authorization')}` : null
        }
      })
    })
  }

  public async getApplicationDetail() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/application`, {
        timeout: 15000,
        headers: {
          authorization: localStorage.getItem('Authorization') ? `Bearer ${localStorage.getItem('Authorization')}` : null
        }
      })
    })
  }

  public async getStatuses() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/statuses`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getTemplateMessages() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/templateMessages`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async login(username: string, password: string) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/requestToken`, {
        username, password
      }, {
        timeout: 15000,
      })
    })
  }

  public async loginWithRefreshToken(token: string, accessToken) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/requestToken`, {
        grantType: 'refresh_token',
        refreshToken: token,
        accessToken
      }, {
        timeout: 15000,
      })
    })
  }

  public async getGroups() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/groups`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getFields() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/fields`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getUser() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/user`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async updateProfile(user: any) {
    return await this.request(async () => {
      return await Axios.patch(`${this.url}/user`, { user }, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async changePassword(oldPassword: string, newPassword: string) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/changePassword`,
      {
        oldPassword, newPassword
      }, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getAgents({ isAvailable }) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/agents`, {
        params: { isAvailable },
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async request(fn: Function) {
    try {
      const response = await fn()
      console.log(response?.config?.method, response?.config?.url)
      console.log(response?.data)
      return response
    } catch (error) {
      if (error.response?.status === 401 && localStorage.getItem('RefreshToken') && localStorage.getItem('AccessToken')) {
        const respLogin = await new LayanV2().loginWithRefreshToken(localStorage.getItem('RefreshToken')!, localStorage.getItem('AccessToken')!)
        localStorage.setItem('user', JSON.stringify({
          _id: respLogin.data.user.mongoId || respLogin.data.user.id,
          role: respLogin.data.user.role,
          name: respLogin.data.user.name,
          isAvailable: respLogin.data.user.isAvailable,
          status: respLogin.data.user.status || { name: 'Online' },
          phone: respLogin.data.user.phone
        }))
        Cookies.set('Authorization', respLogin.data.jwt.token)
        localStorage.setItem('RefreshToken', respLogin.data.jwt.refreshToken)
        localStorage.setItem('Authorization', respLogin.data.jwt.token)
        const getApp = await new Layan().getApplicationDetail()
        localStorage.setItem('AccessToken', getApp.data.application.accessToken)
        return await fn()
      }
      console.log(error.response)
      if (error.response?.status === 404 && error.response?.data.msg === 'No Route Found') {
        Cookies.remove('Authorization')
        Cookies.remove('RefreshToken')
        localStorage.removeItem('RefreshToken')
        localStorage.removeItem('Authorization')
        localStorage.removeItem('AccessToken')
        localStorage.removeItem('url')
        localStorage.removeItem('user')
        localStorage.removeItem('socketId')
        localStorage.removeItem('firebaseToken')
        Socket.destroy()
        window.location.href = '/login'
      }
      else {
        // Cookies.remove('Authorization')
        
        
        console.error(error?.response?.data)
        throw error
      }
    }
  }
}