import Axios from 'axios'
import Cookies from 'js-cookie'
import { Socket } from '../service/Socket'

export class Layan {

  public constructor(private url: string = localStorage.getItem('url')!) {}

  public async getApplication(name: string) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/${name}/application`, {
        timeout: 15000,
        headers: {
          authorization: localStorage.getItem('Authorization') ? `Bearer ${localStorage.getItem('Authorization')}` : null
        }
      })
    })
  }

  public async getApplicationDetail() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/application`, {
        timeout: 15000,
        headers: {
          authorization: localStorage.getItem('Authorization') ? `Bearer ${localStorage.getItem('Authorization')}` : null
        }
      })
    })
  }

  public async login(username: string, password: string) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/requestToken`, {
        username, password
      }, {
        timeout: 15000,
      })
    })
  }

  public async loginWithRefreshToken(token: string, accessToken) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/requestToken`, {
        grantType: 'refresh_token',
        refreshToken: token,
        accessToken
      }, {
        timeout: 15000,
      })
    })
  }

  public async getUser() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/user`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getActiveRooms() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/rooms/assignedTo/me?latestTicketStatus=open&sortBy=desc`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getAllRooms(skip: number = 0, limit: number = 20) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/rooms/assignedTo/me`, {
        params: {
          skip, limit,
          latestTicketStatus: 'solved',
          sortBy: 'desc',
          fromAllTickets: true
        },
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getQueueRooms() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/rooms/queue`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async assign(ticketId: string, userId: string = 'me', params: any = {}) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/assign/${ticketId}/${userId}`, {}, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        },
        params
      })
    })
  }

  public async createUpdateCustomerNote(customerId: string, customerNote) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/customerNote/${customerId}`, { customerNote }, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getTickets(roomId: string, skip: number = 0, limit: number = 5, sort: string = 'desc') {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/room/${roomId}/tickets`, {
        timeout: 15000,
        params: { skip, limit, sort },
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getCustomerNote(customerId: string) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/customerNote/${customerId}`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getTicketDetails(ticketId: string) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/ticket/${ticketId}`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getMessages(ticketId: string, skip: number | null = null, limit: number | null = null, sort: string = 'desc') {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/ticket/${ticketId}/messages`, {
        timeout: 15000,
        params: { skip, limit, sort },
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getRoom(roomId: string) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/room/${roomId}`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getRoomWithSourceId(sourceId: string) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/conversation/rooms`, {
        timeout: 15000,
        params: { sourceId },
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async replyTicket(ticketId: string, message: any) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/ticket/${ticketId}`, { message }, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async solve(ticketId: string) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/solve/${ticketId}`, {}, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getVersion() {
    return await this.request(async () => {
      return await Axios.get(this.url.replace(/\/api\/.*/gi, '/api/version'), {
        timeout: 15000
      })
    })
  }

  public async updateProfile(user: any) {
    return await this.request(async () => {
      return await Axios.patch(`${this.url}/user`, { user }, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async changePassword(oldPassword: string, newPassword: string) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/changePassword`,
      {
        oldPassword, newPassword
      }, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async updateTicket(ticketId: string, ticket: any) {

    return await this.request(async () => {
      return await Axios.patch(`${this.url}/ticket/${ticketId}`, { ticket }, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getStatuses() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/statuses`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getFields() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/fields`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async upload(file: any) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/file`, file, {
        timeout: 120000,
        params: {
          filename: file.name,
          mimetype: file.type
        },
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`,
          'Content-Type': 'application/octet-stream'
        }
      })
    })
  }

  public async getTemplateMessages() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/templateMessages`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getConversations(roomId: string, skip: number = 0, limit: number = 20) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/conversations`, {
        params: {
          roomId, skip, limit, sort: 'desc'
        },
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getConversationsWithSourceId(sourceId: string, skip: number = 0, limit: number = 20) {
    return await Axios.get(`${this.url}/conversations`, {
      params: {
        sourceId, skip, limit, sort: 'desc'
      },
      timeout: 15000,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('Authorization')}`
      }
    })
    // return await this.request(async () => {
    //   return await Axios.get(`${this.url}/conversations`, {
    //     params: {
    //       sourceId, skip, limit, sort: 'desc'
    //     },
    //     timeout: 15000,
    //     headers: {
    //       authorization: `Bearer ${localStorage.getItem('Authorization')}`
    //     }
    //   })
    // })
  }

  public async getRooms(skip: number = 0, limit: number = 20) {
    return await this.request(async () => {
      const currentDate = new Date()
      return await Axios.get(`${this.url}/conversation/rooms`, {
        params: {
          startedAt: new Date(currentDate.setDate(currentDate.getDate() - 8)).toISOString(),
          endedAt: new Date(new Date().setHours(23, 59, 59)).toISOString(),
          skip, limit, sort: 'desc'
        },
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getGroups() {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/groups`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getAgents({ isAvailable }) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/agents`, {
        params: { isAvailable },
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async requeue(ticketId: string) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/requeue/${ticketId}`, {}, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async getTemplatePushMessage(ticketId: string) {
    return await this.request(async () => {
      return await Axios.get(`${this.url}/templatePushMessages/${ticketId}`, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async pushMessage(roomId: string, templateMessage: any) {
    return await this.request(async () => {
      return await Axios.post(`${this.url}/notify/pushReminder/${roomId}`, { templateMessage }, {
        timeout: 15000,
        headers: {
          authorization: `Bearer ${localStorage.getItem('Authorization')}`
        }
      })
    })
  }

  public async request(fn: Function) {
    try {
      const response = await fn()
      console.log(response?.config?.method, response?.config?.url)
      console.log(response?.data)
      return response
    } catch (error) {
      if (error.response?.status === 401 && localStorage.getItem('RefreshToken') && localStorage.getItem('AccessToken')) {
        const respLogin = await new Layan().loginWithRefreshToken(localStorage.getItem('RefreshToken')!, localStorage.getItem('AccessToken')!)
        localStorage.setItem('user', JSON.stringify({
          _id: respLogin.data.user._id,
          role: respLogin.data.user.role,
          name: respLogin.data.user.name,
          isAvailable: respLogin.data.user.isAvailable,
          status: respLogin.data.user.status,
          phone: respLogin.data.user.phone
        }))
        Cookies.set('Authorization', respLogin.data.jwt.token)
        localStorage.setItem('RefreshToken', respLogin.data.jwt.refreshToken)
        localStorage.setItem('Authorization', respLogin.data.jwt.token)
        const getApp = await new Layan().getApplicationDetail()
        localStorage.setItem('AccessToken', getApp.data.application.accessToken)
        return await fn()
      }
      console.log(error.response)
      if (error.response?.status === 404 && error.response?.data.msg === 'No Route Found') {
        Cookies.remove('Authorization')
        Cookies.remove('RefreshToken')
        localStorage.removeItem('RefreshToken')
        localStorage.removeItem('Authorization')
        localStorage.removeItem('AccessToken')
        localStorage.removeItem('url')
        localStorage.removeItem('user')
        localStorage.removeItem('socketId')
        localStorage.removeItem('firebaseToken')
        Socket.destroy()
        window.location.href = '/login'
      }
      else {
        // Cookies.remove('Authorization')
        console.error(error?.response?.data)
        throw error
      }
    }
  }
}