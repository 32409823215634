import React from 'react'
import CustomField from './CustomField'

interface ContainerProps {
  data: any,
  isEditable: boolean,
  values: any,
  FillKeyWithValues: any
}

const CustomFieldRenderer: React.FC<ContainerProps> = ({ data, isEditable, values, FillKeyWithValues }) => {

  let field = null

  if (data !== undefined && values !== undefined){

    for (let i = 0; i < data.length; i++){
      if (data[i].options !== null && data[i].options !== undefined) {
        for (let k = 0; k < data[i].options.length; k++) {
          if (values[i].value !== "") {
            if (values[i].value === data[i].options[k].value) {
              values[i]["name"] = data[i].options[k].name
            }
          }
        }
      }
    }

    field = data.map(
      (item: any, i: number) => {
        const canBeChanged = item.isReadOnly || isEditable
        if (!item.isDisable) {
          return (
          <CustomField
            data={item}
            key={item._id}
            order={i}
            FillKeyWithValues={FillKeyWithValues}
            isEditable={canBeChanged}
            filledValue={values[i]}
            filledName={values[i].name === null || values[i].name === undefined ? "" : values[i].name}
          />)
        }
        return null
      }
    )
  }

  return field
}

export default CustomFieldRenderer