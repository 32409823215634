import React, { useState } from 'react'
import { IonTextarea, IonLabel, IonItem, IonPicker, IonInput, IonText, useIonViewDidEnter } from '@ionic/react'
import { PickerColumn } from '@ionic/core'
import './CustomField.css'

interface ContainerProps {
  data: any,
  isEditable: boolean,
  filledValue: string | any,
  filledName: string,
  FillKeyWithValues: any,
  order: number
}

const CustomField: React.FC<ContainerProps> = ({ data, isEditable, filledValue, filledName, FillKeyWithValues, order }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [value, setValue] = useState<string | any>()
  const [name, setName] = useState<string>()

  useIonViewDidEnter(() => {
    if (filledValue.value === "" || filledValue.value === undefined) {
      if (data.hasOwnProperty("defaultValue")) {
        setValue(data.defaultValue.value)
      }
    } else {
      setValue(filledValue.value)
    }
    if (filledName === "" || filledName === undefined ) {
      if (data.hasOwnProperty("defaultValue")) {
        setName(data.defaultValue.name)
      }
    } else {
      setName(filledName)
    }
  })

  const openMenu = () => {
    if (!isEditable) {
      setIsOpened(!isOpened)
    }
  }
  if (data.hasOwnProperty("defaultValue")) {

  }

  const onSaveText = (incomingValue: any) => {
    setValue(incomingValue)
    FillKeyWithValues(order, incomingValue, null)
  }

  const onSave = (_value: any) => {
    for (const iterables in _value) {
      if (_value.hasOwnProperty(iterables)) {
        const val = _value[iterables].value
        const name = _value[iterables].text
        setValue(val)
        setName(name)
        FillKeyWithValues(order, val, name)
        setIsOpened(false)
      }
    }
  }

  let additionalInfo: any

  if (data.isRequired) {
    additionalInfo = <IonText color="danger">*</IonText>
  }

  if (data !== undefined) {

    if (data.type === "textarea") {
      return (
        <IonItem>
          <IonLabel position="floating">{additionalInfo}{data.title}</IonLabel>
          <IonTextarea disabled={isEditable} onIonChange={e => onSaveText(e.detail.value!)} value={value}></IonTextarea>
        </IonItem>
      )
    } else if (data.type === "select") {
      const columns = {
        name: data.title,
        options: data.options.map(
          (item: any) => {
            return {
                text: item.name,
                value: item.value
            }
          }
        )
      } as PickerColumn

      return (
        <IonItem onClick={openMenu}>
          <div>
            <IonLabel style={{ fontSize: name ? '12px' : 'inherit' }}>{additionalInfo}{data.title}</IonLabel>
            <IonLabel>{name}</IonLabel>
            <IonPicker
              columns={[columns]}
              isOpen={isOpened}
              buttons={[
                {
                  text: "Cancel",
                  role: "cancel",
                },
                {
                  text: "Confirm",
                  handler: value => {
                    onSave(value)
                  }
                }
              ]}
            />
          </div>
        </IonItem>
      )
    } else if (data.type === "input") {
      return (
        <IonItem>
          <IonLabel position="floating" onClick={openMenu}>{additionalInfo}{data.title}</IonLabel>
          <IonInput disabled={isEditable} onIonChange={e => onSaveText(e.detail.value!)} value={value}></IonInput>
        </IonItem>
      )
    }

    return null
    }

    return null
}

export default CustomField