import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import Cookies from 'js-cookie'
import config from './config/config.json'
import { Socket } from './service/Socket'
import { Firebase } from './service/Firebase'
import Main from './pages/Main'
import Login from './pages/Login'
import QueueRooms from './pages/QueueRooms'
import RoomChat from './pages/RoomChat'
import Profile from './pages/Profile'
import TicketDetail from './pages/TicketDetail'
import RoomDetail from './pages/RoomDetails'
import EditProfile from './pages/EditProfile'
import TransferTicket from './pages/TransferTicket'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

const App: React.FC = () => {
  if (localStorage.getItem('theme') === 'dark') {
    document.body.classList.add('dark')
  } else {
    document.body.classList.remove('dark')
  }

  const isLoggedId = () => !!Cookies.get('Authorization')

  const redirect = (to: string = '/login') => <Redirect to={to} />

  if (isLoggedId()) {
    Firebase.run()
    Socket.build(config.url, Cookies.get('Authorization'))
  }

  return (
    <IonApp style={{ maxWidth: '1024px', marginLeft: 'auto', marginRight: 'auto' }}>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/login" component={(props: any) => isLoggedId() ? redirect('/main') : Login(props)} exact={true} />
          <Route path="/main" component={(props: any) => isLoggedId() ? Main(props) : redirect()} />
          <Route path="/queue" component={(props: any) => isLoggedId() ? QueueRooms(props) : redirect()} exact={true} />
          <Route path="/chat/:id/:isRoomId?" component={(props: any) => isLoggedId() ? RoomChat(props) : redirect()} />
          <Route path="/ticket/:id" component={(props: any) => isLoggedId() ? TicketDetail(props) : redirect()} />
          <Route path="/transfer/:id" component={(props: any) => isLoggedId() ? TransferTicket(props) : redirect()} />
          <Route path="/room/:id/:isRoomId?" component={(props: any) => isLoggedId() ? RoomDetail(props) : redirect()} />
          <Route path="/profile" component={(props: any) => isLoggedId() ? Profile(props) : redirect()} exact={true} />
          <Route path="/profile/edit" component={(props: any) => isLoggedId() ? EditProfile(props) : redirect()} exact={true} />
          <Redirect exact path="/" to={Cookies.get('Authorization') ? '/main' : '/login'} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
}

export default App
