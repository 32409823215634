import { IonContent, IonButton, IonIcon, IonToolbar, IonTitle, IonSearchbar, IonList, IonItem } from '@ionic/react'
import React from 'react'
import { close } from 'ionicons/icons'

interface ContainerProps {
  setShowTransfer:any
}

const TransferDialogue: React.FC<ContainerProps> = ({setShowTransfer}) => {


  return (
    <IonContent>
      <IonToolbar>
        <IonButton slot="end" fill="clear" onClick={() => setShowTransfer(false)}>
          <IonIcon slot="icon-only" icon={close} />
        </IonButton>
      </IonToolbar>
      <IonTitle>Transfer thread to...</IonTitle>
      <IonToolbar>
        <IonSearchbar slot="start"></IonSearchbar>
        <IonSearchbar slot="end"></IonSearchbar>
      </IonToolbar>
      <IonList>
        <IonItem>Test 1</IonItem>
        <IonItem>Test 2</IonItem>
        <IonItem>Test 3</IonItem>
      </IonList>
    </IonContent>
  )
}

export default TransferDialogue
